export const portfolioContent = [
	{
		id: 2,
		img: '/images/brand/embroidery-1.webp',
		categorie: 'Embroidery Designs',
		title: 'Digital Embroidery Designs',
	},
	{
		id: 1,
		img: '/images/brand/embroidery-1.webp',
		categorie: 'Embroidery Designs',
		title: 'Embroidery Designs Packs',
	},
	{
		id: 3,
		img: '/images/brand/embroidery-1.webp',
		categorie: 'Pattern Designs',
		title: 'Ready-to-sew Patterns',
	},
	{
		id: 4,
		img: '/images/brand/embroidery-1.webp',
		categorie: 'Prinable Patterns & Designs',
		title: 'Ready-to-print Designs',
	},
];
