export const serviceHomeContent = [
	{
		img: '/images/brand/home/home-embroidery-1.webp',
		href: '/services/embroidery-services',
		title: 'Machine Embroidery',
		description: ` Transform your textiles with our machine embroidery services, where precision meets artistry to breathe life into your designs.`,
	},
	{
		img: '/images/brand/home/home-printing-1.webp',
		href: '/services/printing-services',
		title: 'Printing Services',
		description: ` Bring your ideas to vibrant reality with our printing services, where advanced techniques meet quality and versatility to print your dreams onto fabric.`,
	},
	{
		img: '/images/brand/home/home-digitizing-1.webp',
		href: '/services/digitizing-services',
		title: 'Digitizing Solutions',
		description: ` Elevate your projects with our digitizing solutions, offering top-tier embroidery digitizing and custom print digital illustrations tailored to your unique vision.`,
	},
];

export const printingServicesContent = [
	{
		title: 'Silk Screen Printing',
		icon: '/images/brand/printing-services-page/silk-screen-printing-1.webp',
		props: [
			{
				title: 'Textile:',
				icon: '/images/icons/fabric.png',
				description: 'Any fabric',
			},
			{
				title: 'Time:',
				icon: '/images/icons/fast-time.png',
				description: (
					<div>
						1-5 days
						<br />
						Subject to quantity
					</div>
				),
			},
			{
				title: 'Print on:',
				icon: '/images/icons/clothes.png',
				description: 'Any fabric',
			},
			{
				title: 'Quantity order:',
				icon: '/images/icons/calculator.png',
				description: 'Min. 50 pcs',
			},
			{
				title: 'Color:',
				icon: '/images/icons/color-palette.png',
				description: 'Solid or gradient',
			},
			{
				title: 'Size:',
				icon: '/images/icons/size-guide.png',
				description: 'Any',
			},
			{
				title: 'Image:',
				icon: '/images/icons/gallery.png',
				description: 'Text / Graphic',
			},
			{
				title: 'Applied on:',
				icon: '/images/icons/moon.png',
				description: 'Dark, light, and colored clothes',
			},
		],
	},
	{
		title: 'Sublimation',
		icon: '/images/brand/printing-services-page/sublimation-printing-1.webp',
		props: [
			{
				title: 'Textile:',
				icon: '/images/icons/fabric.png',
				description: 'Any Polyester',
			},
			{
				title: 'Time:',
				icon: '/images/icons/fast-time.png',
				description: (
					<div>
						1-5 days
						<br />
						Subject to quantity
					</div>
				),
			},
			{
				title: 'Print on:',
				icon: '/images/icons/clothes.png',
				description: 'T-Shirts / Workwear / Sportswear',
			},
			{
				title: 'Quantity order:',
				icon: '/images/icons/calculator.png',
				description: 'Min. 50 pcs',
			},
			{
				title: 'Color:',
				icon: '/images/icons/color-palette.png',
				description: 'Anything at all',
			},
			{
				title: 'Size:',
				icon: '/images/icons/size-guide.png',
				description: 'All-over printing, any size',
			},
			{
				title: 'Image:',
				icon: '/images/icons/gallery.png',
				description: 'Anything',
			},
			{
				title: 'Applied on:',
				icon: '/images/icons/moon.png',
				description: 'White or light-colored garments',
			},
		],
	},
	{
		title: 'DTG Printing',
		icon: '/images/brand/printing-services-page/dtg-printing-1.webp',
		props: [
			{
				title: 'Textile:',
				icon: '/images/icons/fabric.png',
				description: '100% Cotton',
			},
			{
				title: 'Time:',
				icon: '/images/icons/fast-time.png',
				description: (
					<div>
						1-5 days
						<br />
						Subject to quantity
					</div>
				),
			},
			{
				title: 'Print on:',
				icon: '/images/icons/clothes.png',
				description: 'T-Shirts',
			},
			{
				title: 'Quantity order:',
				icon: '/images/icons/calculator.png',
				description: 'Min. 50 pcs',
			},
			{
				title: 'Color:',
				icon: '/images/icons/color-palette.png',
				description: 'Solid or gradient',
			},
			{
				title: 'Size:',
				icon: '/images/icons/size-guide.png',
				description: 'Max A3',
			},
			{
				title: 'Image:',
				icon: '/images/icons/gallery.png',
				description: 'Text / Graphic',
			},
			{
				title: 'Applied on:',
				icon: '/images/icons/moon.png',
				description: 'Dark, light, and colored clothes',
			},
		],
	},
	{
		title: 'Heat Transfer Printing',
		icon: '/images/brand/printing-services-page/heat-transfer-printing-1.webp',
		props: [
			{
				title: 'Textile:',
				icon: '/images/icons/fabric.png',
				description: 'Any fabric',
			},
			{
				title: 'Time:',
				icon: '/images/icons/fast-time.png',
				description: (
					<div>
						1-5 days
						<br />
						Subject to quantity
					</div>
				),
			},
			{
				title: 'Print on:',
				icon: '/images/icons/clothes.png',
				description: 'Any fabric',
			},
			{
				title: 'Quantity order:',
				icon: '/images/icons/calculator.png',
				description: 'Min. 50 pcs',
			},
			{
				title: 'Color:',
				icon: '/images/icons/color-palette.png',
				description: 'Solid or gradient',
			},
			{
				title: 'Size:',
				icon: '/images/icons/size-guide.png',
				description: 'Max A3',
			},
			{
				title: 'Image:',
				icon: '/images/icons/gallery.png',
				description: 'Text / Graphic',
			},
			{
				title: 'Applied on:',
				icon: '/images/icons/moon.png',
				description: 'Dark, light, and colored clothes',
			},
		],
	},
];
