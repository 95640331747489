'use client';

import Image from 'next/image';

const brandContent = [
	{
		delayAnimation: '0',
		hoverBg: '#fcf8f4',
		imgHeight: '85px',
		imgName: 'barudan-logo',
		title: 'Barudan',
	},
	{
		delayAnimation: '100',
		hoverBg: '#f3f7fc',
		imgHeight: '78px',
		imgName: 'epson-logo',
		title: 'Epson',
	},
	{
		delayAnimation: '200',
		hoverBg: '#f9fcf3',
		imgHeight: '90px',
		imgName: 'tajima-logo',
		title: 'Tajima',
	},
	{
		delayAnimation: '200',
		hoverBg: '#f3f7fc',
		imgHeight: '90px',
		imgName: 'brother-logo',
		title: 'Brother',
	},
	{
		delayAnimation: '200',
		hoverBg: '#f3f7fc',
		imgHeight: '90px',
		imgName: 'juki-logo',
		title: 'Juki',
	},
	{
		delayAnimation: '200',
		hoverBg: '#f9fcf3',
		imgHeight: '90px',
		imgName: 'aeoon-logo',
		title: 'Aeoon DTG',
	},
];

const Brand = () => {
	return (
		<div className="row" style={{ '--bs-gutter-y': '2rem' }}>
			{brandContent.map((item, i) => (
				<div className="col-6 col-md-3 col-lg-2" key={i}>
					{/* <!--Animated Block--> */}
					<div>
						{/* <!--Partner Box--> */}
						<div
							className="ptf-partner-box"
							style={{
								'--ptf-hover-background': item.hoverBg,
								'--ptf-image-height': item.imgHeight,
							}}
						>
							<div className="ptf-partner-box__image">
								<Image
									width={200}
									height={200}
									style={{
										width: '108px',
										height: '90px',
										objectFit: 'contain',
									}}
									src={`/images/brand/machinery/${item.imgName}.png`}
									alt="Zeplin"
									loading="lazy"
								/>
							</div>
							<h6 className="ptf-partner-box__title">
								{item.title}
							</h6>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Brand;
