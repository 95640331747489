
'use client';

import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';

const Slider = dynamic(() => import('react-slick'), { ssr: false });

import { serviceHomeContent } from '../../data/service';

const ServicesHome = () => {
	const settings = {
		dots: false,
		arrow: true,
		infinite: true,
		speed: 900,
		slidesToShow: 3,
		centerPadding: '0',
		slidesToScroll: 1,
		autoplay: false,
		centerMode: true,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					dots: true,
					centerMode: false,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					dots: true,
				},
			},
		],
	};

	return (
		<div className="ptf-content-slider swiper-container slide-portfolio">
			<div className="swiper-wrapper">
				<Slider {...settings}>
					{serviceHomeContent.map((val, i) => (
						<Link className="" key={i} href={val.href}>
							<div className="grid-item filter-1 filter-4">
								<article className="ptf-post ptf-post--style-5">
									<div className="ptf-post__media">
										<Image
											width={1200}
											height={1200}
											style={{
												width: '100%',
												objectFit: 'cover',
											}}
											src={val.img}
											alt="blog"
											loading="lazy"
										/>
										<div className="ptf-post__media__content">
											<header className="ptf-post__header">
												<p className="ptf-post__title">
													{val.title}
												</p>
											</header>
										</div>
									</div>
									<div className="ptf-post__content hidden">
										<div className="ptf-post__excerpt">
											<p>{val.description}</p>
										</div>
										<footer className="ptf-post__footer">
											<span className="ptf-read-more-link">
												{val.title}
											</span>
										</footer>
									</div>
								</article>
							</div>
						</Link>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default ServicesHome;
