'use client';

const HeroAgency = () => {
	return (
		<div
			className="ptf-showcase-image image-overlay h-[45rem]"
			style={{
				backgroundImage: `url("/images/brand/embroidery-1.webp")`,
			}}
		>
			<div className="ptf-showcase-slide h-full">
				<div className="container flex flex-col gap-10">
					<h1 className="lh-1 has-white-color text-center font-normal md:text-6xl">
						Sustainable customized clothing for your brand.
					</h1>

					<p className=" has-white-color text-center md:text-3xl">
						A full service clothing factory with over 20 years of
						experience. We can help you to improve sales, and win
						client’s trust, and expand your market share.
					</p>

					<div className="d-flex justify-content-center">
						{/* <!--Button--> */}
						<a
							className="ptf-btn ptf-btn--primary"
							href=""
							target="_self"
						>
							Get Your Quote
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroAgency;
